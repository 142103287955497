<template>
  <div>
    <div class="hxcp"></div>
    <!-- 内容一 -->
    <div class="content1 container">
      <show :styles="{ top: '5em', left: '-20em' }" :moveData="{ left: '6em' }">
        <div class="infos">
          <p class="title1">数据监视与控制平台</p>
          <p class="title2">LinkScada</p>
          <p class="border"></p>
          <p class="text">
            LinkScada定位于面向公用事业行业（水、电、气）的数据监视与控制平
          </p>
          <p class="text">台，通过“SaaS化软件+场景化硬件”模式，为行业中小企业客户提供</p>
          <p class="text">具有超高性价比、超短实施周期、超强灵活性的物联网解决方案。</p>
        </div>
      </show>
      <show :styles="{ top: '5em', left: '50em' }" :moveData="{ left: '35em' }">
        <img src="../../assets/images/bg_img/LinkScada.png" alt="" />
      </show>
    </div>
    <!-- 内容二 -->
    <div class="content2 container">
      <show :styles="{ top: '3em', left: '50em' }" :moveData="{ left: '35em' }">
        <p class="common_title title_sty">平台功能</p></show
      >
      <show :styles="{ top: '8em', left: '-20em' }" :moveData="{ left: '9em' }">
        <leftTable />
      </show>
      <show :styles="{ top: '8em', left: '70em' }" :moveData="{ left: '44em' }">
        <rightTable />
      </show>
    </div>
    <!-- 内容三 -->
    <div class="content3 container">
      <show :styles="{ top: '3em', left: '50em' }" :moveData="{ left: '35em' }">
        <p class="common_title title_sty">平台特点</p></show
      >
      <show :styles="{ top: '8em', left: '-20em' }" :moveData="{ left: '8em' }">
        <img src="../../assets/images/bg_img/stand_special.png" alt="" />
      </show>
    </div>

    <!-- 内容四 -->
    <div class="content4 container">
      <show :styles="{ top: '3em', left: '50em' }" :moveData="{ left: '35em' }">
        <p class="common_title title_sty">应用场景</p></show
      >
      <show :styles="{ top: '8em', left: '-20em' }" :moveData="{ left: '10em' }">
        <ul>
          <li v-for="(e, i) in scadas" :key="i">
            <img :src="e.src" alt="" />
            <p class="title">{{ e.title }}</p>
            <p class="text" v-for="(e2, i2) in e.texts" :key="i2">{{ e2 }}</p>
          </li>
        </ul>
      </show>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    leftTable: (resolve) => require(["../../components/tables/leftTable"], resolve),
    rightTable: (resolve) => require(["../../components/tables/rightTable"], resolve),
  },
  data() {
    return {
      scadas: [
        {
          src: require("../../assets/images/bg_img/scada_img (1).png"),
          title: "供排水管网/厂站监测",
          texts: [
            "针对城市供排水管网 (压力/流量/水",
            "质等) 、水厂、污水处理厂、加压泵",
            "站、提升泵站、二次供水泵房的在线",
            "监测",
          ],
        },

        {
          src: require("../../assets/images/bg_img/scada_img (2).png"),
          title: "水利/水环境监测/厂站监测",
          texts: [
            "针对河道、流域、水库、罐区的水质.",
            "流量、液位、雨量、污染物、界桩等",
            "信息的监测",
          ],
        },
        {
          src: require("../../assets/images/bg_img/scada_img (3).png"),
          title: "城市燃气管网/场站监测",
          texts: [
            "针对城市燃气管网 （压力/流量等）、",
            "城市门站、调压站、撬装站、泄露报",
            "警点等信息的在线监测",
          ],
        },
        {
          src: require("../../assets/images/bg_img/scada_img (4).png"),
          title: "分布式能源监测",
          texts: [
            "针对分布式能源设施（风电、太阳能",
            "板、充电桩等）的运行状态、产能数",
            "据、故障信息等数据的监测",
          ],
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.content1 {
  height: 29.3em;
  .title {
    width: 18em;
    font-size: 1.5em;
    font-weight: bold;
  }
  img {
    width: 40em;
  }

  .title1 {
    color: rgb(0, 112, 215);
    font-size: 1.9em;
  }
  .title2 {
    color: rgb(0, 112, 215);
    font-size: 2.75em;
    font-weight: bold;
  }
  .border {
    width: 5.375em;
    height: 4px;
    background-color: #626462;
    margin: 1.5em 0;
  }
  .text {
    width: 33em;
    font-size: 0.875em;
    color: #4b4b4b;
    margin-bottom: 0.5em;
  }
}

.content2 {
  height: 42.375em;
  img {
    width: 23.75em;
  }
}
.content3 {
  height: 30.6875em;
  img {
    width: 63.625em;
  }
}
.content4 {
  height: 28.625em;
  ul {
    width: 58em;
    display: flex;
    justify-content: space-between;
  }
  .title {
    font-weight: bold;
    font-size: 1.1em;
    margin-top: 0.6em;
    margin-bottom: 0.6em;
  }
  .text {
    font-size: 0.85em;
    color: #b4aeb0;
  }
  img {
    width: 13.5em;
    height: 10.5em;
  }
}
</style>
